<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="6" cols="12" class="py-0">
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>
                  <v-btn
                    depressed
                    small
                    class="mx-2"
                    color="deep-purple lighten-2"
                    @click="$router.go(-1)"
                    dark
                    mdi-keyboard-backspace
                    ><v-icon left> mdi-keyboard-backspace </v-icon> Back</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>

            <div style="display: flex; justify-content: center">
              <v-app>
                <page
                  size="A4"
                  class="img2"
                  :style="{ backgroundImage: 'url(' + bg + ')' }"
                >
                  <div style="padding: 80px 80px 100px 80px">
                    <v-row>
                      <v-col sm="12" cols="12">
                        <v-row>
                          <v-col sm="12" cols="12">
                            <div align="center" style="font-size: 20px">
                              <div>Kingdom of Cambodia</div>
                              <div>Nation Religion King</div>
                            </div>
                            <img
                              class="mt-6"
                              width="250px"
                              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2FCADT-Masterbrand-Logos-Navy_CADT-Lockup-2-Khmer-English%20(2).png?alt=media&token=79c137a9-7232-481f-8f38-e340d1574255"
                            />

                            <div class="text-left">
                              No:..................................CADT
                            </div>
                          </v-col>
                          <!-- <v-col sm="12" cols="12">
                        <img
                          width="110px"
                          src="../../assets/images/niptict_logo.png"
                        />
                        <div class="text-left">
                          National Institute of Posts,
                        </div>
                        <div class="text-left">Telecom and ICT</div>
                        <div class="text-left">
                          No:...................NIPTICT
                        </div>
                      </v-col> -->
                        </v-row>
                      </v-col>
                    </v-row>
                    <div class="mt-1"></div>
                    <v-row>
                      <v-col
                        sm="12"
                        cols="12"
                        class="ml-6"
                        style="font-size: 16px"
                      >
                        <v-row>
                          <v-col sm="7" cols="12">
                            <p>
                              <label>Degree</label>
                              <label style="padding-left: 1em">:</label>
                              <label style="padding-left: 3px">Bachelor</label>
                            </p>
                            <p>
                              <label>ID</label>
                              <label style="padding-left: 2.9em">:</label>
                              <label style="padding-left: 3px">{{
                                listStudentDetails.student_id
                              }}</label>
                            </p>
                            <p>
                              <label>Name</label>
                              <label style="padding-left: 1.6em">:</label>
                              <label
                                style="
                                  padding-left: 3px;
                                  text-transform: uppercase;
                                "
                                >{{ listStudentDetails.name_en }}</label
                              >
                            </p>
                          </v-col>
                          <v-col sm="5" cols="12">
                            <p>
                              <label>Class</label>
                              <label style="padding-left: 1em">:</label>
                              <label style="padding-left: 3px">{{
                                listTranscripts.major_name
                              }}</label>
                            </p>
                            <p>
                              <label>Year</label>
                              <label style="padding-left: 1.3em">:</label>
                              <label style="padding-left: 3px">{{
                                listTranscripts.year
                              }}</label>
                            </p>
                            <p>
                              <label>Sex</label>
                              <label style="padding-left: 1.8em">:</label>
                              <label style="padding-left: 3px">{{
                                listStudentDetails.gender
                              }}</label>
                            </p>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-row>
                        <v-col sm="12" cols="12">
                          <div align="center">
                            <div style="font-size: 25px">
                              Academic Transcript
                            </div>
                            <div class="mt-1"></div>
                            <div>
                              {{ listTranscripts.academic }}
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-row>
                    <div class="mt-5"></div>
                    <v-row>
                      <v-col sm="12" cols="12">
                        <table style="width: 100%">
                          <thead>
                            <tr>
                              <th class="text-left pl-2">Subject</th>
                              <th>Credit</th>
                              <th>Grades</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(trans, index) in annualScore"
                              v-bind:key="index"
                            >
                              <td class="text-left pl-2">
                                {{ 1 + index++ }}- {{ trans.subject }}
                              </td>
                              <td class="text-center">
                                {{ trans.credit }}
                              </td>
                              <td class="text-center">
                                {{ trans.grading_value }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col sm="12" cols="12">
                        <div class="text-left">
                          <div style="font-size: 20px" class="mb-3">
                            GPA: {{ listTranscripts.gpa_value }}
                          </div>
                          <div style="font-size: 20px">GRADING SYSTEM:</div>
                        </div>

                        <div class="ml-5 mt-4">
                          <v-row no-gutters>
                            <v-col
                              sm="6"
                              cols="12"
                              v-for="(item, i) in grading"
                              :key="i"
                            >
                              <p style="line-height: 2px">
                                <label>{{ item.value }}</label>
                                <label class="pl-2">=</label>
                                <label class="pl-2"
                                  >{{ item.from_percentage }}%-</label
                                >
                                <label>{{ item.to_percentage }}%</label>
                                <label class="pl-2">=</label>
                                <label class="pl-3"
                                  >{{ item.gpa_value }} =</label
                                >
                                <label>{{ item.gpa_name }}</label>
                              </p>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="text-left">
                          <div style="font-size: 20px" class="mb-5 un">
                            Remark:
                          </div>

                          <div class="ml-5">
                            <p style="line-height: 2px">
                              *The annual Grade Point Average minimum
                              requirement to pass to higher class is 2.0 .
                            </p>
                            <p style="line-height: 2px">
                              *This transcript cannot be given for the second
                              time.
                            </p>
                          </div>
                        </div>
                        <v-row>
                          <v-col sm="12" cols="12">
                            <div>
                              <p class="text-right" style="line-height: 2px">
                                Certified to be in accordance with
                              </p>
                              <p
                                class="text-right pr-10"
                                style="line-height: 2px"
                              >
                                the original copy
                              </p>
                              <p class="text-right" style="line-height: 2px">
                                Phnom Penh,....................,2021
                              </p>
                              <p
                                class="text-right pr-10"
                                style="line-height: 2px"
                              >
                                Vice President
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <template>
                      <v-footer
                        absolute
                        style="
                          padding-left: 50px;
                          padding-right: 50px;
                          background-color: transparent;
                        "
                      >
                        <v-col sm="12" cols="12">
                          <v-divider
                            style="background-color: #7c9ab0"
                          ></v-divider>
                        </v-col>

                        <v-row>
                          <v-col sm="12" cols="12">
                            <label
                              class="float-left"
                              style="font-size: 12px; color: #7c9ab0"
                              >Address: National Road 6A, Kthor Prek Leap Chroy
                              Changva Phnom Penh</label
                            >
                            <label
                              class="float-right"
                              style="font-size: 12px; color: #7c9ab0"
                              >Phone: (+855) 10 340 000</label
                            >
                          </v-col>
                        </v-row>
                      </v-footer>
                    </template>
                  </div>
                </page>
              </v-app>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>

<script>
import { generateTranscript } from "@schoolbase/web-client-lib";
import { INSTITUTE_NAME } from "@schoolbase/core";
import Loading from "vue-loading-overlay";
import store from "@/store";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  components: {
    Loading,
  },
  data: () => ({
    bg: "https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2FCapture-removebg-preview.png?alt=media&token=60228f4d-15ed-496a-974d-f62a58229bc3",
    snackbar: {
      show: false,
      message: null,
      color: null,
    },
    isLoading: false,
    instituteName: INSTITUTE_NAME,
    myLoading: false,
    listTranscripts: [],
    token: store.getters.getToken,
    // step: listTranscripts,
    annualScore: [],
    grading: [],
    listStudentDetails: [],
  }),
  mounted() {
    this.transcript();
  },
  computed: {},
  methods: {
    async transcript() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await generateTranscript(
          this.$route.params.student_id,
          this.$route.params.annual_id,
          this.token
        );
        this.grading = APIResponse.payload.grading;
        this.listTranscripts = APIResponse.payload;
        this.annualScore = APIResponse.payload.value;
        this.listStudentDetails = APIResponse.payload.student;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }
        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;
        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
  },
};
</script>
<style scoped>
body {
  background: rgb(204, 204, 204);
}
::v-deep .v-application--wrap {
  min-height: fit-content;
}
page {
  background: white;
  display: block;
  font-family: "Times New Roman", serif;
  margin: 0 auto;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

page[size="A4"] {
  width: 21cm;
  height: 29cm;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  border: solid 1px #000000;
}
.un {
  text-decoration: underline;
}
table tr th {
  border: solid 1px #000000;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  text-align: center;
}
table tr td {
  border-bottom: thin short;
  border-right: solid 1px #020202;
}
.img2 {
  background-repeat: no-repeat;
  background-size: 600px 240px;
  background-position: center;
  /* Center and scale the image nicely */
}
@media print {
  body,
  page {
    margin: 0;
    box-shadow: 0;
  }
}
@media (max-width: 576px) {
}
</style>
